var w = window,
	d = document,
	b = 'body',
	viewport = 'html, body';

var getBodyId = $(b).attr('id');

var throttleTime = 150, // Event firing every 'n' seconds
	debounceTime = 30;  // Event firing after 'n' seconds

/*
	console.log($(w).innerHeight());
	console.log(w.screen.height);
	console.log(w.innerHeight);
*/

var userAgent = navigator.userAgent;

/*
 * user agent for mobile
 */
var userAgentIphone = userAgent.indexOf('iPhone') > -1 && userAgent.indexOf('iPod') == -1,
	userAgentAndroid = userAgent.indexOf('Android') > -1 && navigator.userAgent.indexOf('Mobile') > -1,
	userAgentMobile = userAgentIphone || userAgentAndroid;

var MobileFlg;

if(userAgentMobile) {
	MobileFlg = true;
	
	$(b).addClass('ua-mbl');
}

/*
 * user agent for IE
 */
var userAgentIe = userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident') > -1 || userAgent.indexOf('Edge') > -1,
	userAgentIeLgcy = userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident') > -1;

if(userAgentIeLgcy) {
	$(b).addClass('ua-ie ua-ie-lgcy');
} else if(userAgentIe) {
	$(b).addClass('ua-ie');
}

/*
 * user agent for macOs
 */
var userAgentMacOsMbl = userAgent.indexOf('Mac OS') > -1,
	userAgentMacOsSfr = userAgent.indexOf('Mac OS') > -1 && userAgent.indexOf('Chrome') == -1 && userAgent.indexOf('Firefox') == -1,
	userAgentMacOs = MobileFlg && userAgentMacOsMbl || userAgentMacOsSfr;

if(userAgentMacOs) {
	$(b).addClass('ua-mac-os');
}

/*
 * smooth scroll
 */
var smsc = '.js-smsc a, a.js-smsc',
	toTop = '.js-totop',
	speed = 380,
	easing = 'easeInSine';

$(smsc).on('click', function() {
	var href = $(this).attr('href'),
		target = $(href == '#' || href == '' ? 'html' : href),
		position = target.offset().top;
	
	$(viewport).animate({scrollTop: position}, speed, easing);
	return false;
});

$(w).on('scroll', $.throttle(throttleTime,
	function() {
		if ($(this).scrollTop() > 200) {
			$(toTop).addClass('is-actv');
		} else {
			$(toTop).removeClass('is-actv');
		}
	})
);

/*
	$(d).on('click','.pagetop',function(){
		$viewport.on('scroll mousedown DOMMouseScroll mousewheel keyup', function(e){
			if( e.which > 0 || e.type === 'mousedown' || e.type === 'mousewheel'){
				$viewport.stop().off('scroll mousedown DOMMouseScroll mousewheel keyup');
			}
		}).animate({ scrollTop: 0 },500,'linear');
		return false;
	}).on('click','.smsc',function(){
		var href = $(this).attr('href').replace(/^.*#/, "#"),
			pos = $(href == "#" || href == "" ? viewport : href ).offset().top;
		$viewport.on('scroll mousedown DOMMouseScroll mousewheel keyup', function(e){
			if( e.which > 0 || e.type === 'mousedown' || e.type === 'mousewheel'){
				$viewport.stop().off('scroll mousedown DOMMouseScroll mousewheel keyup');
			}
		}).animate({ scrollTop: pos },300,'linear');
		return false;
	});
*/

/*
 * match height
 */
$('.js-mh').matchHeight();

/*
 * change svg style
 */
deSVG('.js-de-svg', true);

/*
 * google map
 */
var lat, lng, map,
	mapCamFlg,
	gMapMrkIcn = '/common/img/img_gmap_mrk_akg.png';

var gMapMrkArr = [
	{
		name: '本社・AKAGI R&D FUTURE LABO',
		addr: '〒366-0824<br>埼玉県深谷市西島町2-12-1',
		lat: 36.192753,
		lng: 139.282503,
		lnk: 'https://www.google.com/maps/place/%E8%B5%A4%E5%9F%8E%E4%B9%B3%E6%A5%AD+%E6%9C%AC%E7%A4%BE+AKAGI+R%26D+FUTURE+LABO/@36.1927302,139.2803322,17z/data=!3m1!4b1!4m5!3m4!1s0x601edbd9938e3673:0xb494375bdb6e4e3!8m2!3d36.1927259!4d139.2825262',
	},
	{
		name: '深谷工場',
		addr: '〒366-8502<br>埼玉県深谷市上柴町東2-27-1',
		lat: 36.180042,
		lng: 139.311611,
		lnk: 'https://www.google.com/maps/place/%E8%B5%A4%E5%9F%8E%E4%B9%B3%E6%A5%AD(%E6%A0%AA)+%E6%B7%B1%E8%B0%B7%E5%B7%A5%E5%A0%B4/@36.1795055,139.3091884,17z/data=!3m1!4b1!4m5!3m4!1s0x601ed9b8effec77b:0xdcad7fda917d0eef!8m2!3d36.1795012!4d139.3113824',
	},
	{
		name: '本庄千本さくら『5S』工場',
		addr: '〒367-0212<br>埼玉県本庄市児玉町児玉850-10',
		lat: 36.181157,
		lng: 139.148978,
		lnk: 'https://www.google.com/maps/place/%E8%B5%A4%E5%9F%8E%E4%B9%B3%E6%A5%AD+%E6%9C%AC%E5%BA%84%E5%8D%83%E6%9C%AC%E3%81%95%E3%81%8F%E3%82%89%E3%80%8C%EF%BC%95%EF%BC%B3%E3%80%8D%E5%B7%A5%E5%A0%B4/@36.1812407,139.1467917,17z/data=!3m1!4b1!4m5!3m4!1s0x601ec3d1f4d80ed5:0x2687fb0d3b35c371!8m2!3d36.1812364!4d139.1489857',
	},
];

function gMapCam() {
	mapCamFlg = true,
	lat = gMapMrkArr[0].lat,
	lng = gMapMrkArr[0].lng,
	map = new GMaps({
			div: '#gmap-fct',
			lat: lat,
			lng: lng,
			zoom: 11,
			disableDefaultUI: true,
			zoomControl: true,
			scrollwheel: false,
		});
	
	for(var i = 0; i < gMapMrkArr.length; i++) {
		map.addMarker({
			title: gMapMrkArr[i].name,
			lat: gMapMrkArr[i].lat,
			lng: gMapMrkArr[i].lng,
			icon: {
				url: gMapMrkIcn,
				scaledSize: new google.maps.Size(74, 28)
			},
			infoWindow: {
				content: '<dl class="gMapInfDtl"><dt class="gMapInfDtl_trm">' + gMapMrkArr[i].name + '</dt><dd class="gMapInfDtl_dsc">' + gMapMrkArr[i].addr + '</dt><dd class="gMapInfDtl_dsc"><a class="gMapInfDtl_lnk over" href="' + gMapMrkArr[i].lnk + '" target="_blank">Google MAP</a></dd></dl>'
			},
		});
	}
	
	map.fitZoom();
}

if(getBodyId == 'access') {
	gMapCam();
}

/*
 * if only resize horizontal
 */
var winReSizeWidth = 0;

$(w).on('load resize', $.throttle(throttleTime, reSizeHr));

function reSizeHr() {
	var winCurtWidth = $(w).width();
	
	if(winCurtWidth != winReSizeWidth) {
		console.log(true);
		
		/*
		 * object fit images
		 */
		objectFitImages('.js-obj-fit-img');
		
		if(mapCamFlg) {
			map.fitZoom();
		}
	}
	
	winReSizeWidth = winCurtWidth;
}

/*
 * import test
 */
// import 文を使って _hello-world.js ファイルを読み込む。
import {helloWorld} from './vendor/_hello-world';
// import './vendor/_import-test';

// _hello-world.js に定義された JavaScript を実行する。
helloWorld();


/*
 * spMenu
 */

$('.spMenuBtn').on("click", function() {
	$(this).toggleClass('active');
	$('.spMenu').stop().slideToggle();
});


/*
 * bxslider
 */
$('.bxslider').bxSlider({
	auto: true
});


/*
 * global_switch
 */

$('.global_switch').on("click", function() {
	var getPath = window.location.pathname,
		getHref = $(this).attr('href');
	
	window.open(getHref + getPath);	
	return false;
});
